import React, { useRef, useState, useEffect } from "react";
import { Collection, FormAction, Group, Input, Section } from "../Utils/Forms/Interface";
import { DynamicForm } from "../Utils/Forms/Form";
import { Button, Modal, Card, Spinner } from "react-bootstrap";
import { ApiInterfaces } from "../../Interfaces";
import UnsavedModal from "../Utils/Unsaved";
import APIManager from "../../Server/Api/APIInterface";
import QRCodeReader from "../Utils/QRCodeReader";
import { Devices } from "../Senquip/types";
import { ReduxActions } from "../../redux/actions";
import { FaSave, FaTimesCircle } from 'react-icons/fa';
import { ApiHelper } from "../../Server/Events/ApiOperations";
import { useSelector } from "react-redux";
import { IRedux } from "../../Interfaces/redux";


interface IProps {
    show: boolean;
    handleClose: () => void;
    coordinates: { lat: number, lng: number };
}

type FormData = Partial<ApiInterfaces.Device & { deviceType: string }>;

const CreateDeviceModal: React.FC<IProps> = ({ show, handleClose, coordinates }) => {
    const [dialog, setDialog] = useState(false);
    const [device, setDevice] = useState<FormData>({ lat: coordinates.lat, lng: coordinates.lng });
    const [showScanner, setShowScanner] = useState(false);
    const [scanned, setScanned] = useState<boolean>(false);
    const formRef = useRef<FormAction<FormData>>();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state: IRedux.MapState) => state.auth)

    const checkForChanges = () => {
        const form = formRef.current;
        if (form?.getFormChanged()) {
            setDialog(true);
        } else {
            handleClose();
        }
    };

    const saveForm = async () => {
        const form = formRef.current;
        const data = form?.getFormData();

        if (data) {
            await ApiHelper.op("device.create", { ...device, ...data, companyId: user.companyId, serial: device.variable, deviceType: device.deviceType }, user.companyId || '')
            handleClose();
        }
    };

    const filterLogs: Section = {
        type: "section",
        title: "",
        id: "main",
        items: [
            {
                type: "row",
                id: "Idsrow",
                items: [
                    { width: 6, type: "text", id: "name", label: "Name", value: "[name]" },
                    { width: 6, type: "text", id: "type", label: "Type", value: "[class]", disabled: true },
                    { width: 6, type: "number", id: "lat", label: "Latitude", value: "[lat]", disabled: true },
                    { width: 6, type: "number", id: "lng", label: "Longitude", value: "[lng]", disabled: true },
                    {
                        width: 12,
                        label: undefined,
                        type: "map",
                        id: "map",
                        value: "",
                        defaultZoom: 13,
                        defaultCenter: { lat: device?.lat || coordinates.lat, lng: device?.lng || coordinates.lng },
                        onDrag: (event) => {
                            formRef.current?.setValue("lat", event.latLng.lat());
                            formRef.current?.setValue("lng", event.latLng.lng());
                        },
                    },
                ],
            },
        ],
    };

    const renderForm = () => (
        <Card className="text-center w-100 mb-5" style={{ border: '1px solid #555', borderRadius: '8px' }}>
            <Card.Body>
                <DynamicForm<FormData>
                    actionTriggered={(id, action, form) => {
                        formRef.current = form;
                        return true;
                    }}
                    valueChanged={(id, value, valid, form) => {
                        console.log(id, value, valid, form)
                        return true;
                    }}
                    id="DeviceForm"
                    inputData={device}
                    content={[filterLogs]}
                />
            </Card.Body>
        </Card>

    );

    const handleScanSuccess = async (decodedText: string) => {
        const regex = /^se:([^;]+);pi:([^;]+);t:([^;]+)$/;
        const match = decodedText.match(regex);
        if (match) {
            setScanned(true);
            const serial = match[1];
            const pin = match[2];
            const sensor = match[3];
            const deviceData = Devices.device[sensor];
            if (deviceData) {
                setLoading(true);
                const res = await APIManager.snRequest("senquip.verify", { serial, pin, managementsId: user.managementsId || '' });
                console.log(res)
                setLoading(false);
                if (res.type === "senquip.verify") {
                    ReduxActions.ShowSuccess("Qr code scanned successfully");
                    setDevice(prevDevice => ({
                        ...prevDevice,
                        name: deviceData.name,
                        class: deviceData.class,
                        metadata: deviceData.metadata,
                        graph_metadata: deviceData.graph_metadata,
                        variable: serial,
                        deviceType: sensor
                    }));
                    if (formRef.current) {
                        formRef.current.setValue("name", deviceData.name);
                        formRef.current.setValue("type", deviceData.class);
                    }
                } else if (res.type === "error") {
                    ReduxActions.ShowError(`An error occurred while processing the QR code ${res.error}`,);
                    setScanned(false);
                }
            } else ReduxActions.ShowError("No device found for the given sensor ID");
        }
    };

    return (
        <>
            <UnsavedModal
                action={handleClose}
                show={dialog}
                handleClose={() => setDialog(false)}
            />
            <Modal show={show} onHide={checkForChanges} className="qc_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Create New Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showScanner ? (
                        <QRCodeReader onClose={() => setShowScanner(false)} onScanSuccess={handleScanSuccess} />
                    ) : (
                        <Card className="text-center w-100 mb-5" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                            <Card.Body>
                                <i className="fas fa-qrcode fa-5x mb-3 text-muted"></i>
                                <Card.Title>Ready to scan a QR code?</Card.Title>
                                <Button variant="outline-warning" onClick={() => setShowScanner(true)} className="mt-3">
                                    <i className="fas fa-qrcode"></i>&nbsp;&nbsp;&nbsp;   Click Here to Scan
                                </Button>
                            </Card.Body>
                            {loading && (
                                <>
                                    <Spinner animation="border" role="status" className="mt-3 text-warning">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    <div className="mb-3 text-warning">Awaiting verification</div>
                                </>
                            )}
                        </Card>
                    )}
                    <div className={scanned ? "" : "d-none"}>
                        {renderForm()}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="success" onClick={saveForm} className="d-flex align-items-center me-3">
                        <FaSave size={22} className="me-2" /> SAVE
                    </Button>
                    <Button variant="danger" onClick={checkForChanges} className="d-flex align-items-center">
                        <FaTimesCircle size={22} className="me-2" /> CANCEL
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateDeviceModal;
