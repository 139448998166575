import { ApiInterfaces } from "../../Interfaces";
import { GraphBuilder } from "../Graphs/Default";

export const SenquipMetadata: ApiInterfaces.metadata = {
    "vsys": {
        "name": "System Voltage",
        "unit": "V",
        "value": "",
        "svg": "",
        "visible": true
    },
    "vbat": {
        "name": "Battery Voltage",
        "unit": "V",
        "value": "",
        "svg": "",
        "visible": true
    },
    "vin": {
        "name": "Supply Voltage",
        "unit": "V",
        "value": "",
        "svg": "Voltage",
        "visible": true
    },
    "capture1": {
        "name": "capture1",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": false
    },
    "ambient": {
        "name": "ambient",
        "unit": "°C",
        "value": "",
        "svg": "Temperature",
        "visible": true
    },
    "light": {
        "name": "light",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": false
    },
    "state": {
        "name": "state",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": false
    },
    "ts": {
        "name": "ts",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": false
    },
    "time": {
        "name": "time",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": false
    },
    "current1": {
        "name": "current1",
        "unit": "A",
        "value": "",
        "svg": "Current",
        "visible": true
    },
    "wifi_ip": {
        "name": "wifi_ip",
        "unit": "",
        "value": "",
        "svg": "",
        "visible": true
    },
    "wifi_rssi": {
        "name": "wifi_rssi",
        "unit": "dBm",
        "value": "",
        "svg": "",
        "visible": true
    }
}

export const SenquipGraphMetadata: GraphBuilder.Metadata[] = [
    {
        "key": "System-Voltage(V)",
        "color": "#006cff",
        "values": [],
        "disabled": false,
        "context": "vsys"
    },
    {
        "key": "Battery-Voltage(V)",
        "color": "#ff3502",
        "values": [],
        "disabled": false,
        "context": "vbat"
    },
    {
        "key": "Supply-Voltage(V)",
        "color": "#04c143",
        "values": [],
        "disabled": false,
        "context": "vin"
    },
    {
        "key": "Ambient(°C)",
        "color": "#2389DA",
        "values": [],
        "disabled": false,
        "context": "ambient"
    },
    {
        "key": "Current(A)",
        "color": "#9B59B6",
        "values": [],
        "disabled": false,
        "context": "current1"
    },
    {
        "key": "WiFi-RSSI(dBm)",
        "color": "#F1C40F",
        "values": [],
        "disabled": false,
        "context": "wifi_rssi"
    }
]
