import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";

export type DataType = Events['senquip.list']['response']['data'][number];

export class Binding extends DataSet<DataType> {
    public constructor(private managementsId: string,cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();
        this.registerBindingKey(await this.manager.bind('senquip.list', {
            topic: `${this.managementsId}/senquip`,
            managementsId: this.managementsId
        }, async (res) => {
            if (res.type === 'senquip.list') {
                this.setData(res.data);
            } else {
                this.setLoaded();
            }
        }));
    }
}