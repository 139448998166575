import { v4 as uuid } from 'uuid';
import { KeyMap } from '../../Helper/Core/interface';
import { ApiEventTypes, Events } from '../Events/Events';
import { ErrorResponse } from './APIInterface';
import APIManager from './APIInterface';
import MqttService from './MqttService';
import { BindHelper } from '../Binding/helper';
import { ReduxActions } from '../../redux/actions';

type APIBinding<T extends ApiEventTypes> = {
    listeners: ((response: any, error: any) => void)[],

    type: T,
    requestBody: Omit<Events[T]['request'], 'type'>,
    // includeToken: boolean,

    bindings: KeyMap<boolean>,
    boundIds: KeyMap<string>,
    data: { responded: boolean, items: { [key: string]: any } },
};
const fullBinding: any = {};


export class MqttManager extends APIManager {
    private static instance: MqttManager;
    private readonly client: MqttService;
    private token: string
    static client: MqttService;
    private apiCalls: {
        [key: string]: APIBinding<any>,
    } = {};

    private ids: {
        [key: string]: {
            cacheKey: string,
            listener: (response: any, error: any, id: string) => void,
            topic: string
        },
    } = {}


    private constructor(token: string) {
        super();
        this.token = token

        this.client = new MqttService(process.env.REACT_APP_MQTT as string, this.token);
        const bindings: { [key: string]: { [key: string]: string } } = {};
        Object.values(this.apiCalls).forEach((api) => {
            bindings[api.type] = bindings[api.type] || {};
            Object.entries(api.boundIds).forEach(([id, type]) => {
                bindings[api.type][id] = type;
            });
        });
        Promise.all(
            Object.values(this.apiCalls)
                .map((api) => this.makeAPICall(api, APIManager.getAccessToken(undefined)))
        );


        this.client.addHandler((message) => {
            console.log('Messages on add Handler? ', message, this.apiCalls)
            if (message.action === 'data.change' || message.action === 'data.add' || message.action === 'data.delete' || message.action === 'data.list') {
                const apiBindings: KeyMap<boolean> = {};
                ((message.body.binding || []) as string[]).forEach((binding) => {
                    apiBindings[binding] = true;
                });
                Object.values(this.apiCalls)
                    .forEach((api) => {
                        if (Object.keys(api.bindings).filter(b => apiBindings[b]).length > 0) {
                            let matched = false;
                            ((message.body.boundIds || []) as string[]).forEach((id) => {
                                matched = matched || api.boundIds[id] !== undefined;
                            });
                            if (!matched) matched = api.bindings[message.body.binding[0]]
                            if (matched) {
                                if (message.action === 'data.delete') BindHelper.deleteAPIData(api.data, message.body.data);
                                else if (message.action === 'data.list') BindHelper.updateMultipleAPIData(api.data, message.body.data);
                                else BindHelper.updateAPIData(api.data, message.body.data);
                                const response = BindHelper.synthesisAPIResponse(api.data, api.type as any);
                                api.listeners.forEach(cb => {
                                    try {
                                        cb(response, undefined)
                                    } catch (error) {
                                    }
                                });
                            }
                        }
                    });
            }
        });
    }

    public static Instance(): MqttManager {
        const mqttToken = APIManager.getMqttToken(undefined)
        if (!MqttManager.instance && mqttToken) {
            MqttManager.instance = new MqttManager(mqttToken || "");
            MqttManager.instance.connect()
        }
        return MqttManager.instance;
    }

    public static Reconnect(token: string) {
        if (MqttManager.instance.token !== token) {
            const mqttToken = APIManager.getMqttToken(token)
            mqttToken && MqttManager.instance.reconnect(mqttToken);
        }

    }


    public disconnect(): void {
        this.client.disconnect()
    }

    private connect(): void {
        this.client.connect()

    }

    private reconnect(token: string): void {
        this.token = token
        this.client.reconnect(token)
    }

    private async snApiCall<T extends ApiEventTypes>(api: APIBinding<T>): Promise<Events[T]['response'] | ErrorResponse> {
        return await APIManager.snRequest(api.type, { ...api.requestBody });
    }

    private ApiBind<T extends ApiEventTypes>(api: APIBinding<T>, response: Events[T]['response'] | ErrorResponse) {

        if (response.type !== 'error') {
            api.data.responded = true;
            const data = BindHelper.getAPIData(api.requestBody as any, response as Events[T]['response']);
            Object.entries(data).forEach(([id, data]) => api.data.items[id] = data);
            const { ids, binding } = BindHelper.getAPIResponseBinding(response as Events[T]['response']);

            if (binding) {
                fullBinding[binding] = fullBinding[binding] || {};
                ids.forEach(i => {
                    fullBinding[binding][i.id] = true;
                });

                api.bindings[binding] = true;

                const unbound = ids.filter(i => api.boundIds[i.id] === undefined);
                if (unbound.length > 0) {
                    unbound.forEach((i) => {
                        api.boundIds[i.id] = i.type;
                    });
                }
            }
        }
        const fullResponse = BindHelper.synthesisAPIResponse(api.data, api.type as any);

        api.listeners.forEach(cb => {
            try {
                cb(fullResponse, undefined);
            } catch (error) {
                // Handle callback error
            }
        });
    }

    private async makeAPICall<T extends ApiEventTypes>(api: APIBinding<T>, data?: any) {
        try {
            const { ids, binding } = BindHelper.getAPIRequestBinding({ type: api.type, ...api.requestBody } as any);
            if (binding) {
                fullBinding[binding] = fullBinding[binding] || {};
                ids.forEach(i => {
                    fullBinding[binding][i.id] = true;
                });
                api.bindings[binding] = true;

                const unbound = ids.filter(i => api.boundIds[i.id] === undefined);
                if (unbound.length > 0) {
                    unbound.forEach((i) => {
                        api.boundIds[i.id] = i.type;
                    });
                }
            }
            const response = data ? data : await this.snApiCall(api)
            this.ApiBind(api, response)

        } catch (err: any) {
            api.listeners.forEach(cb => {
                try {
                    cb(undefined, err);
                } catch (error) {
                    // Handle callback error
                }
            });
            if (err.response && err.response.data && err.response.data.error) {
                ReduxActions.ShowError(err.response.data.error)
            }
            console.log(err);
        }
    }


    public async bind<T extends ApiEventTypes>(
        type: T,
        body: Omit<Events[T]['request'], 'type'>,
        cb: (response: Events[T]['response'] | ErrorResponse, error: any) => void,
    ): Promise<string> {
        const { topic, skipBinding, ...args } = body as any;
        const id = uuid();
        const cacheKey = `${type}-${JSON.stringify(args)}`;
        let api = this.apiCalls[cacheKey];

        const newBinding = api === undefined;
        if (api === undefined) {
            api = {
                type: type,
                requestBody: body,
                listeners: [],
                data: { responded: false, items: {} },
                boundIds: {},
                bindings: {},
            }
            this.apiCalls[cacheKey] = api;
        }
        if (api.data.responded) {
            const fullResponse = BindHelper.synthesisAPIResponse(api.data, api.type as any);
            cb(fullResponse, undefined);
        }
        api.listeners.push(cb);
        this.ids[id] = {
            cacheKey,
            listener: cb,
            topic
        }
        if (newBinding) {
            this.client.bind(topic)
            await this.makeAPICall(api, skipBinding);
        }
        return id;
    }


    public unbind(): void {
    }

    public getMqttService(): MqttService {
        return this.client
    }

    public release(bindingToken: string) {
        const idData = this.ids[bindingToken];
        if (idData === undefined) return
        const api = this.apiCalls[idData.cacheKey];
        if (api === undefined) return

        api.listeners = api.listeners.filter((l) => (l !== idData.listener));
        this.client.unbind(this.ids[bindingToken].topic)
        delete this.apiCalls[idData.cacheKey];
        delete this.ids[bindingToken];
    }

}

export const GetMqtt = (): MqttService => {
    return MqttManager.Instance().getMqttService();
};

export const GetManager = (): MqttManager => {
    return MqttManager.Instance();
};

export const DisconectMqtt = () => {
    MqttManager.Instance().disconnect();
};

export default MqttManager





