import { ApiInterfaces } from "../../Interfaces"
import { GraphBuilder } from "../Graphs/Default"

export namespace Devices {
    export type Map = {
      [key: string]: {
        id: string,
        name: string,
        class: string,
        metadata: ApiInterfaces.metadata
        graph_metadata: GraphBuilder.Metadata[] | null
      }
    }

    export const device: Map = {
        "VantagePro2": {
            id: "VantagePro2",
            name: "Weather Station",
            class: "WeatherStation",
            metadata: {
                Temperature: {
                    value: "",
                    unit: "°C",
                    name: "Temperature",
                    svg: "Temperature",
                    visible: true
                },
                Humidity: {
                    value: "",
                    unit: "%",
                    name: "Humidity",
                    svg: "Humidity",
                    visible: true
                },
                WindSpeed: {
                    value: "",
                    unit: "km/h",
                    name: "WindSpeed",
                    svg: "WindSpeed",
                    visible: true
                },
                WindDirection: {
                    value: "",
                    unit: "",
                    name: "WindDirection",
                    visible: true,
                },
                Rainfall: {
                    name: "Rainfall",
                    unit: "mm",
                    value: "",
                    svg: "",
                    visible: false
                },
                RainfallToday:{
                    name: "RainfallToday",
                    unit: "mm",
                    value: "",
                    svg: "",
                    visible: false
                },
                Gust: {
                    name: "Gust",
                    unit: "km/h",
                    value: "0",
                    svg: "",
                    visible: false
                },
            },
            graph_metadata: [
                {
                    key: "Humidity(%)",
                    color: "#006cff",
                    values: [],
                    disabled: false,
                    context: "Humidity"
                },
                {
                    key: "Temperature(C)",
                    color: "#ff3502",
                    values: [],
                    disabled: false,
                    context: "Temperature"
                },
                {
                    key: "Wind-Speed(km/h)",
                    color: "#04c143",
                    values: [],
                    disabled: true,
                    context: "WindSpeed"
                },
                {
                    key: "Rainfall(mm)",
                    color: "#2389DA",
                    values: [],
                    disabled: true,
                    context: "RainfallToday"
                }
            ]
        }
    }
}