import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ApiInterfaces } from '../../Interfaces';
import { SenquipGraphMetadata } from '../Senquip/metadata';
import Chart from './Charts';

interface GraphModalProps {
    show: boolean;
    handleClose: () => void;
    device: ApiInterfaces.Device | undefined;
    key: string | undefined
    senquip?: ApiInterfaces.Senquip | undefined

}

function GraphModal(props: GraphModalProps) {
    const { show, device, senquip, handleClose } = props;

    return (
        (device || senquip) ?
            <Modal keepMounted show={show} onHide={handleClose} dialogClassName="graph_modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {device ? `${device.name} - Trends` : senquip ? `${senquip.serial} - Trends` : 'Trends'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {device && 
                        <Chart 
                            device={device} 
                            key={device.id} 
                            type={'line'} 
                            shared={!device.aggregate} 
                        />
                    }
                    {senquip &&
                        <Chart 
                            device={senquip} 
                            key={senquip.id} 
                            type={'line'} 
                            customMetadata={SenquipGraphMetadata}
                        />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>
                        <i className="far fa-times-circle"></i> &nbsp; Close
                    </Button>
                </Modal.Footer>
            </Modal>
            : <></>
    );
}



export default GraphModal
