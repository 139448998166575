import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { Button, Card, Col, Row, Collapse } from 'react-bootstrap';
import { Panel, PanelBody } from '../panel/panel';
import { useNavigate } from 'react-router-dom';
import { HiOutlineDocumentAdd, HiOutlineDocumentReport } from 'react-icons/hi';
import { CiEdit } from 'react-icons/ci';
import { RiErrorWarningFill } from "react-icons/ri";
import { BindingHooks } from '../../Server/Binding/bidings/BindingHook';
import { ApiInterfaces } from '../../Interfaces';
import { FaAd, FaPlus } from 'react-icons/fa';
import SenquipModal from './modal';
import APIManager from '../../Server/Api/APIInterface';
import { MdDetails, MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import SenquipData from './data';
import { TbListDetails } from 'react-icons/tb';

const Senquip: React.FC = () => {
    const { user } = useSelector((state: IRedux.MapState) => state.auth);
    const { data } = BindingHooks.Senquip(user.managementsId || '', 'Senquip', true);
    const { data: managment } = BindingHooks.Management(user.managementsId || '', 'Management', true)



    const [showModal, setShowModal] = useState(false);
    const [senquip, setSenquip] = useState<Partial<ApiInterfaces.Senquip>>({});

    const [viewData, setViewData] = useState<{ [key: string]: boolean }>({});

    const [devices, setDevices] = useState<ApiInterfaces.ListDevices | undefined>(undefined)


    useEffect(() => {
        const fetchData = async () => {
            const res = await APIManager.snRequest(`management.devices.list`, { id: user.managementsId || "" })
            if (res.type === 'management.devices.list') setDevices(res.data)
        }
        fetchData()
    }, []);



    const openModal = (senquip: Partial<ApiInterfaces.Senquip>) => {
        setSenquip(senquip);
        setShowModal(true);
    };

    const toggleDeviceData = (deviceId: string) => {
        setViewData(prev => ({
            ...prev,
            [deviceId]: !prev[deviceId]
        }));
    };




    return (
        user.managementsId === process.env.REACT_APP_ROOT ? (
            senquip ? (
                <>
                    <SenquipModal show={showModal} handleClose={() => {
                        setShowModal(false);
                        setSenquip({});
                    }} key={senquip.id || "new"} senquip={senquip} devices={devices || undefined} companies={managment?.companies || []} />

                    <Row className="justify-content-center">
                        <Col xs={12} md={8} xl={5}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <PanelBody className="p-6">
                                    <h3 className="text-center"> Senquip Devices </h3>
                                </PanelBody>
                            </Panel>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8} xl={5}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <PanelBody className="p-6">
                                    <Button variant={'outline-warning'} className="d-flex align-items-center mb-3" onClick={() => setShowModal(true)}>
                                        <FaPlus size={18} className="me-2" /> Add Senquip
                                    </Button>
                                    <hr />
                                    <div className="mt-3">
                                        {data?.map((senquip, index) => (
                                            <Card key={index} className="mb-3 shadow border rounded" style={{ borderColor: '#6c757d' }}>
                                                <Card.Body className="d-flex justify-content-between align-items-center">
                                                    <span className="d-flex align-items-center ps-3">
                                                        <HiOutlineDocumentReport size={20} className="me-2 text-primary" />
                                                        <strong>{senquip.serial}</strong>
                                                    </span>
                                                    <div className="d-flex">
                                                        <Button
                                                            variant="outline-success"
                                                            className="me-3 d-flex align-items-center"
                                                            onClick={() => toggleDeviceData(senquip.id || '')}
                                                        >
                                                            <MdOutlineKeyboardDoubleArrowDown
                                                                size={20}
                                                                className="me-1"
                                                                style={{
                                                                    transform: viewData[senquip.id || ''] ? 'rotate(180deg)' : 'none',
                                                                    transition: 'transform 0.3s'
                                                                }}
                                                            />
                                                            <span className="d-none d-md-inline-block">
                                                                {viewData[senquip.id || ''] ? 'Hide Data' : 'View Data'}
                                                            </span>
                                                        </Button>
                                                        <Button variant="outline-warning" className="me-2 d-flex align-items-center" onClick={() => openModal(senquip)}>
                                                            <TbListDetails size={20} className="me-1" />
                                                            <span className="d-none d-md-inline-block">Details</span>
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                                <Collapse in={viewData[senquip.id || '']}>
                                                    <div>
                                                        <Card.Footer>
                                                            {<>
                                                                {senquip.deviceId ? (
                                                                    <SenquipData senquip={senquip} />
                                                                ) : (
                                                                    <div className="text-center text-info p-4 d-flex align-items-center justify-content-center">
                                                                        <RiErrorWarningFill  size={20} className="me-2" />
                                                                        <span className="">Senquip pending client assignment, please wait for the client to adopt the device</span>
                                                                    </div>
                                                                )}
                                                            </>}
                                                        </Card.Footer>
                                                    </div>
                                                </Collapse>
                                            </Card>
                                        ))}
                                    </div>
                                </PanelBody>
                            </Panel>
                        </Col>
                    </Row>

                </>
            ) : <></>
        ) : (
            <Row className="justify-content-center mt-5">
                <Col xs={12} md={6} xl={4}>
                    <Card className="shadow">
                        <Card.Body className="text-center p-5">
                            <h3 className="mb-3">Not Authorized</h3>
                            <p className="text-muted mb-0">You do not have permission to access this page.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    );
};

export default Senquip;