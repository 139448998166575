import { IoT } from "../../Helper/IoT"
import { ApiInterfaces } from "../../Interfaces"
import { ReduxActions } from "../../redux/actions"
import Highcharts from "highcharts";
import axios from "axios";


export namespace GraphBuilder {

    export const HIGHCHARTS_URL = "https://export.highcharts.com/"

    export interface Metadata {
        key: string
        color: string
        values: any[]
        disabled: boolean
        context: string
        visible?: boolean
        type?: string,
        tooltip?: {
            valueDecimals: number,
            valueSuffix: string
        },
        negativeColor?: string
    }

    export interface PlotlineLabel {
        text: string,
        align: 'right' | 'left' | 'center',
        x: number,
        y?: number,
        style?: {
            color?: `#${string}`,
            fontWeight?: string
        }
    }

    export interface Plotline {
        color: `#${string}`,
        width: number,
        value: number,
        zIndex?: number,
        label?: PlotlineLabel
    }

    export function generateHighChartsWithMetadata(data: ApiInterfaces.DeviceData[] | ApiInterfaces.SenquipData[], metadata: Metadata[], type: any, shared?: boolean) {
        const metadataMap = new Map<string, Metadata>();
        const maxDecimalsMap = new Map<string, number>();

        metadata.forEach(entry => {
            metadataMap.set(entry.context, { ...entry, values: [] });
            maxDecimalsMap.set(entry.context, 0);
        });

        const allTimestamps = new Set<number>();
        data.forEach(item => {
            const timestamp = new Date(item.timestamp).getTime();
            allTimestamps.add(timestamp);
            Object.keys(item.payload || {}).forEach(context => {
                const metadataEntry = metadataMap.get(context);
                if (metadataEntry) {
                    const value = item.payload[context] || 0;
                    metadataEntry.values.push([timestamp, Number(value)]);
                    // Calculate the number of decimal places
                    const decimalPlaces = (value.toString().split('.')[1] || '').length;
                    maxDecimalsMap.set(context, Math.max(maxDecimalsMap.get(context) || 0, decimalPlaces));
                }
            });
        });

        const sortedTimestamps = Array.from(allTimestamps).sort((a, b) => a - b);

        if (shared === false) {
            metadataMap.forEach((metadataEntry, context) => {
                const valueMap = new Map<number, number>();
                metadataEntry.values.forEach(([timestamp, value]) => {
                    valueMap.set(timestamp, value);
                });

                const knownValues = metadataEntry.values.sort((a, b) => a[0] - b[0]);
                const interpolatedValues: [number, number][] = [];
                let lastValue: [number, number] | null = null;
                let nextIndex = 0;

                for (const timestamp of sortedTimestamps) {
                    const value = valueMap.get(timestamp);

                    if (value !== undefined) {
                        interpolatedValues.push([timestamp, value]);
                        lastValue = [timestamp, value];
                    } else {
                        // Find the next known value using binary search
                        while (nextIndex < knownValues.length && knownValues[nextIndex][0] <= timestamp) {
                            nextIndex++;
                        }

                        const nextValue = nextIndex < knownValues.length ? knownValues[nextIndex] : null;

                        if (lastValue && nextValue) {
                            // Linear interpolation
                            const [lastTimestamp, lastVal] = lastValue;
                            const [nextTimestamp, nextVal] = nextValue;
                            const interpolatedVal = lastVal + (nextVal - lastVal) * ((timestamp - lastTimestamp) / (nextTimestamp - lastTimestamp));
                            // Round to the maximum number of decimal places
                            const maxDecimals = maxDecimalsMap.get(context) || 0;
                            const roundedVal = parseFloat(interpolatedVal.toFixed(maxDecimals));
                            interpolatedValues.push([timestamp, roundedVal]);
                        } else if (lastValue) {
                            // If no next value, use the last known value
                            interpolatedValues.push([timestamp, lastValue[1]]);
                        }
                    }
                }

                metadataEntry.values = interpolatedValues;
            });
        }

        const highchart: Highcharts.SeriesOptionsType[] = Array.from(metadataMap.values()).map(({
            key,
            values,
            color,
            type: optional,
            visible,
            negativeColor,
            tooltip
        }) => ({
            name: key,
            data: values,
            type: optional || type,
            tooltip,
            color: color,
            visible: visible,
            negativeColor: negativeColor
        }));

        return highchart;
    }


    export const Options = (name: string, shared: boolean, height?: number, noTime?: boolean, labelY?: string, labelX?: string, plotlineY?: Plotline[]): Highcharts.Options =>
    (
        {
            series: [],
            time: {
                timezone: 'Australia/Sydney'
            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: false
                    }
                },
                series: {
                    borderColor: '#303030'
                }
            },
            lang: {
                noData: "Fetching Data..."
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#FFFF'
                }
            },
            title: {
                text: `${name}`,
                style: {
                    color: "#FFF",
                    fontWeight: "bold"
                }
            },
            tooltip: {
                shared: true,
                enabled: true,
                useHTML: true,
                backgroundColor: "#1C242D",
                borderColor: "#1C242D",
                borderWidth: 1,
                borderRadius: 0,
                style: {
                    color: "#FFFFFF",
                    fontSize: "15px"
                },
                headerFormat: `<table><tr><th colspan="2">${noTime && labelX ? `${labelX} ` : ''}{point.key}</th></tr>`,
                pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
                    '<td style="text-align: right"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
            },

            subtitle: {
                style: {
                    color: "#FFF",
                    fontWeight: "bold"
                }
            },
            legend: {
                enabled: true,
                align: "center",
                verticalAlign: "bottom",
                itemStyle: {
                    color: "#C0C0C0"
                },
                itemHoverStyle: {
                    color: "#C0C0C0"
                },
                itemHiddenStyle: {
                    color: "#444444"
                }
            },
            chart: {
                zooming: {
                    type: 'x'
                },
                height: window.innerWidth < 900 ? (window.innerHeight * 0.7) : height || null,
                backgroundColor: "#212529",
                style: {
                    fontFamily: "Roboto",
                    color: "#000000"
                }
            },
            xAxis: {
                gridLineColor: "#2E3740",
                gridLineWidth: 1,
                type: !noTime ? 'datetime' : 'linear',
                labels: {
                    style: {
                        color: "#FFF"
                    },
                    format: `${!noTime ? `{value:%d-%m}` : ''}`
                },

                lineColor: "#2E3740",
                tickColor: "#2E3740",
                title: {
                    style: {
                        color: "#FFFFFF"
                    }
                }
            },
            yAxis: {
                gridLineColor: "#2E3740",
                gridLineWidth: 1,
                labels: {
                    style: {
                        color: "#FFFF"
                    },
                },
                plotLines: plotlineY || [],
                lineColor: "#2E3740",
                tickColor: "#2E3740",
                title: {
                    style: {
                        color: "#FFFFFF"
                    },
                    text: `${labelY || ""}`
                }
            },
            navigation: {
                menuItemHoverStyle: {
                    fontWeight: 'bold',
                    background: 'none',
                    color: 'black'
                },

                menuItemStyle: {
                    fontWeight: 'normal',
                    fontSize: '14px',
                    background: 'none',
                    borderLeft: '2px solid #3179b1',
                    borderRadius: 0,
                },
                buttonOptions: {
                    // align: 'left',
                    enabled: true,
                    useHTML: true,
                    buttonSpacing: 10,
                    y: -10,
                    theme: {
                        fill: "#212529",
                        stroke: "none",

                        // @ts-ignore
                        style: {
                            fontSize: '25px',
                            color: '#ffc107'
                        },
                        states: {
                            hover: {
                                style: {
                                    color: '#000'
                                },
                                fill: '#ffc107',
                                stroke: '#FFFF'
                            }
                        }
                    }
                },
            }
        }
    )

    export const GetData = async (device: ApiInterfaces.Device, start: Date, type: any, finish?: Date, customMetadata?: Metadata[], shared?: boolean) => {
        try {
            const iotData = await IoT.getTimeSeriesData(device, "aa", start, finish || new Date())
            const metadata = customMetadata || JSON.parse(JSON.stringify(device.graph_metadata) || '') as Metadata[]
            if (iotData) return generateHighChartsWithMetadata(iotData.data, metadata, type, shared)
            else return []
        } catch (error) {
            ReduxActions.ShowError('Unable to Fetch Data from Api...')
            return []
        }
    }

    export const GetDataSenquip = async (device: ApiInterfaces.Senquip, start: Date, type: any, finish?: Date, customMetadata?: Metadata[], shared?: boolean) => {
        try {
            const iotData = await IoT.getSenquipTimeSeriesData(device, start, finish || new Date())
            const metadata = customMetadata || []
            if (iotData) return generateHighChartsWithMetadata(iotData.data, metadata, type, shared)
            else return []
        } catch (error) {
            ReduxActions.ShowError('Unable to Fetch Data from Api...')
            return []
        }
    }

    
    


    export const StorageCurveSeries = (storage: ApiInterfaces.LIDStorage): Highcharts.SeriesOptionsType[] => {
        const data: { x: number; y: number; }[] = []
        storage.storageCurve.forEach(s => {
            data.push({ x: s.volume, y: s.elevation })
        })
        return [{
            data,
            type: 'line',
            name: 'Water Elevation (mHAD)'
        }]
    }

    export const StorageOptions = (avg: number, top: number): GraphBuilder.Plotline[] => [{
        color: "#ac0b0b",
        value: avg,
        width: 2,
        zIndex: 5,
        label: {
            text: `Average Top Bank ${avg} `,
            align: 'left',
            x: 12,
            y: -10,
            style: {
                color: "#128b16",
                fontWeight: 'bold',
            }
        }
    },
    {
        color: "#d8792e",
        value: top,
        width: 2,
        zIndex: 5,
        label: {
            text: `Top Water Level ${top}`,
            align: 'left',
            x: 12,
            y: -10,
            style: {
                color: "#128b16",
                fontWeight: 'bold'
            }
        }
    }]

    export const getBase64 = async (src: string) => {
        try {
            const res = await axios.post<string>(HIGHCHARTS_URL, {
                type: "png",
                b64: true,
                infile: src
            });
            return res.data
        } catch (e) {
            ReduxActions.ShowError("Unable to retrieve graph")
        }

    }

    export function switchToBlack(chartRef: any) {
        chartRef.chart.update({
            chart: {
                backgroundColor: "#212529",
                style: {
                    color: "#000000"
                }
            },
            xAxis: {
                gridLineColor: "#2E3740",
                labels: {
                    style: {
                        color: "#FFF"
                    }
                },
                lineColor: "#2E3740",
                tickColor: "#2E3740",
                title: {
                    style: {
                        color: "#FFFFFF"
                    }
                }
            },
            yAxis: {
                gridLineColor: "#2E3740",
                labels: {
                    style: {
                        color: "#FFFF"
                    }
                },
                lineColor: "#2E3740",
                tickColor: "#2E3740",
                title: {
                    style: {
                        color: "#FFFFFF"
                    }
                }
            },
            legend: {
                itemStyle: {
                    color: "#C0C0C0"
                },
                itemHoverStyle: {
                    color: "#C0C0C0"
                },
                itemHiddenStyle: {
                    color: "#444444"
                }
            },
            tooltip: {
                backgroundColor: "#1C242D",
                borderColor: "#1C242D",
                style: {
                    color: "#FFFFFF"
                }
            }
        });
    }

    export function switchToLightModeAndExportSVG(chartRef: any) {
        // Update chart options to use a light mode theme
        chartRef.chart.update({
            chart: {
                backgroundColor: "#FFF", // Change background color to white
                style: {
                    color: "#000" // Change text color to black
                }
            },
            xAxis: {
                gridLineColor: "#EEE", // Change grid line color
                labels: {
                    style: {
                        color: "#000" // Change label text color to black
                    }
                },
                lineColor: "#EEE", // Change axis line color
                tickColor: "#EEE", // Change tick color
                title: {
                    style: {
                        color: "#000" // Change title text color to black
                    }
                }
            },
            legend: {
                enabled: true,
                align: "center",
                verticalAlign: "bottom",
                itemStyle: {
                    color: "#000"
                },
                itemHoverStyle: {
                    color: "#000"
                },
                itemHiddenStyle: {
                    color: "#000"
                }
            },
            yAxis: {
                gridLineColor: "#EEE", // Change grid line color
                labels: {
                    style: {
                        color: "#000" // Change label text color to black
                    }
                },
                lineColor: "#EEE", // Change axis line color
                tickColor: "#EEE", // Change tick color
                title: {
                    style: {
                        color: "#000" // Change title text color to black
                    }
                }
            }
        });
        // Return the SVG content
        return chartRef.chart.getOptions();
    }
}