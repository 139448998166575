import { useState } from "react";
import { Bindings } from "./index";
import { ApiInterfaces } from "../../../Interfaces";
import { Binding as CompanyBinding } from "./Company";
import { IoT } from "../../../Helper/IoT";
import { ReduxActions } from "../../../redux/actions";
import { HelperFunctions } from "../../../Helper/functions";

export namespace BindingHooks {

    export const LidStorage = (accessToken: string, deviceId: string, companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.LIDStorage | undefined>(undefined)

        function CreateBinding() {
            return (new Bindings.LidStorage.Binding(accessToken, deviceId, companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data)[0])
                }
            ));
        }

        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };

    export const UserNotification = (userId: string, companyId: string, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.Expo_notification[] | undefined>(undefined)
        const bind = (new Bindings.UserNotification.Binding(userId, companyId,
            (data) => setData(Object.values(data).reverse())
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };

    export const Device = (companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.Device[] | undefined>(undefined)

        function CreateBinding() {
            return (new Bindings.Device.Binding(companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data))
                }
            ));
        }
        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };


    export const StorageDevice = (companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<HelperFunctions.groupBy<ApiInterfaces.Device> | undefined>(undefined)

        function CreateBinding() {
            return (new Bindings.Device.Binding(companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(HelperFunctions.groupBy(Object.values(data).filter(s => s.class === "Storage") || [], 'class'))
                }
            ));
        }

        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };

    export const IOTLastValue = (device: ApiInterfaces.Device, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.DeviceData | undefined>(undefined)
        const bind = (new Bindings.IOTLastValue.Binding(device.id,
            (data) => setData(Object.values(data)[0])
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };

    export const IOT = (device: ApiInterfaces.Device, start: Date, finish: Date, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.DeviceData[] | undefined>(undefined)
        const bind = (new Bindings.IOT.Binding(device.companyId, device.id, start, finish,
            (data) => setData(Object.values(data))
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };

    export const IOTSenquip = (senquip: ApiInterfaces.Senquip, companyId: string, start: Date, finish: Date, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.SenquipData[] | undefined>(undefined)
        const bind = (new Bindings.IOTSenquip.Binding(companyId, senquip.id, start, finish,
            (data) => setData(Object.values(data))
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };


    export const IOTLastValuePanel = (device: ApiInterfaces.Device, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.metadata>({})
        const bind = (new Bindings.IOTLastValue.Binding(device.id,
            (data) => setData(IoT.updatePanelData(device, Object.values(data)[0].payload, Object.values(data)[0].timestamp))
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };

    export const IOTSenquipLast = (senquip: ApiInterfaces.Senquip, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.metadata>({})
        const bind = (new Bindings.IOTSenquipLast.Binding(senquip.id,
            (data) => setData(IoT.updateSenquipPanelData(Object.values(data)[0].payload, Object.values(data)[0].timestamp))
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    };


    export const User = (accessToken: string, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.User[] | undefined>(undefined)
        const bind = (new Bindings.User.Binding(accessToken,
            (data) => {
                setData(Object.values(data))
            }
        ));
        Bindings.Hook(bind, bindId)
        return data;
    };

    export const Template = (companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.Template[] | undefined>(undefined)
        function CreateBinding() {
            return (new Bindings.Template.Binding(companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data))
                }
            ));
        }
        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };

    export const Senquip = (companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.Senquip[] | undefined>(undefined)
        function CreateBinding() {
            return (new Bindings.Senquip.Binding(companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data))
                }
            ));
        }
        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };

    export const Management = (mgnId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.Managements | undefined>(undefined)
        function CreateBinding() {
            return (new Bindings.Management.Binding("", mgnId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data)[0])
                }
            ));
        }
        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };

    export const GetTemplate = (id: string, companyId: string, bindId: string, loader?: boolean) => {
        const [data, setData] = useState<ApiInterfaces.Template | undefined>(undefined)
        function CreateBinding() {
            return (new Bindings.GetTemplate.Binding(id, companyId,
                (data) => {
                    loader && ReduxActions.HideLoader()
                    setData(Object.values(data)[0])
                }
            ));
        }
        Bindings.NewHook(CreateBinding, bindId, loader)
        return { data, setData };
    };



    export const RefDevice = (ref: CompanyBinding, bindId: string) => {
        const [data, setData] = useState<ApiInterfaces.Device[] | undefined>(undefined)
        const bind = (new Bindings.RefDevice.Binding(true, ref,
            (data) => setData(Object.values(data))
        ));
        Bindings.Hook(bind, bindId)
        return { data, setData };
    }
}



