import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";

export type DataType = Omit<Events['iot.list.senquip']['response']['data'][number], "type">;
const type = 'iot.list.senquip';

export class Binding extends DataSet<DataType> {
    public constructor(private companyId: string, private senquipId: string, private start: Date, private finish: Date, cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();

        this.registerBindingKey(await this.manager.bind(type, {
            topic: `${this.companyId}/${this.senquipId}/iot`,
            senquipId: this.senquipId,
            start: this.start,
            finish: this.finish
        }, async (res) => {
            if (res.type === type) {
                this.setData(res.data);
            } else {
                this.setLoaded();
            }
        }));
    }
}