import MqttService from "./MqttService";

export const waitForMqttResponse = <T>(mqtt: MqttService, topic: string, timeoutMs: number = 10000): Promise<T> => {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            mqtt.unsubscribe(topic);
            reject(new Error(`Timeout waiting for response on ${topic}`));
        }, timeoutMs);

        const subscription = mqtt.getMessage(topic).subscribe({
            next: (message: string) => {
                clearTimeout(timeout);
                subscription.unsubscribe();
                mqtt.unsubscribe(topic);
                try {
                    const response = JSON.parse(message);
                    resolve(response);
                } catch (e) {
                    reject(new Error('Invalid response format'));
                }
            },
            error: (error: Error) => {
                clearTimeout(timeout);
                reject(error);
            }
        });
    });
};