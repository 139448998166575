import { useEffect, useRef, useState } from 'react'
import { Button, Card, Table, Collapse, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ApiInterfaces } from '../../Interfaces'
import { IRedux } from '../../Interfaces/redux'
import { SVG, SVG_Panel, SvgIcon } from '../Utils/SVG'
import { Subscription } from 'rxjs'
import { IoT } from '../../Helper/IoT'
import { GetMqtt } from '../../Server/Api/MqttManager'
import { BindingHooks } from '../../Server/Binding/bidings/BindingHook'
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md'
import { HelperTime } from '../../Helper/Time'
import TimeAgo from 'react-timeago';
import { BsGraphUpArrow } from 'react-icons/bs'
import ChartModal from '../Graphs/ChartModal'

type IProps = {
    senquip: ApiInterfaces.Senquip,
    reload?: boolean
}

const SenquipData = ({ senquip }: IProps) => {

    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState<ApiInterfaces.Senquip | undefined>(undefined)
    const { mqttToken } = useSelector((state: IRedux.MapState) => state.auth);
    const { data, setData } = BindingHooks.IOTSenquipLast(senquip, `live-${senquip.id}`);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        const stream: Subscription = GetMqtt().getMessage(`${senquip.companyId}/${senquip.id}/Live/`).subscribe(async (message) => {
            console.log(message)
            setData({ ...IoT.updateSenquipPanelData(JSON.parse(message), new Date()) });
            if (timerRef.current) clearTimeout(timerRef.current);
        });
        return () => {
            stream.unsubscribe();
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [mqttToken]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const renderPreview = () => {
        const svgMetadata = Object.entries(data).filter(([, value]) => value.svg);
        return (
            <div
                className='position-relative'
                onClick={() => setExpand(!expand)}
                style={{ cursor: 'pointer' }}
            >
                <div className='d-flex justify-content-center align-items-center p-3 w-100'>
                    <div className='d-flex flex-wrap gap-1 justify-content-center align-items-center'>
                        {svgMetadata.map(([k, v]) => (
                            v.visible && v.svg && v.svg in SVG_Panel.Icon &&
                            <div key={k}
                                className="d-flex align-items-center px-2 py-2"
                                style={{
                                    minWidth: '110px',
                                    flex: '1 1 auto'
                                }}>
                                <SvgIcon
                                    height={30}
                                    width={30}
                                    element={SVG_Panel.Icon[v.svg]}
                                    color={v.color || undefined}
                                />
                                <div className='ms-3'>
                                    <span className='fw-bolder'>{v.value}</span>
                                    <span className='ms-1 text-secondary'>{v.unit || ""}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Button
                        variant="link"
                        size="sm"
                        className="position-absolute end-0 me-2"
                        style={{ border: 'none', boxShadow: 'none' }}
                    >
                        <MdOutlineKeyboardDoubleArrowDown
                            size={20}
                            style={{
                                transform: expand ? 'rotate(180deg)' : 'none',
                                transition: 'transform 0.3s'
                            }}
                        />
                    </Button>
                </div>
            </div>
        );
    };

    const renderPanel = () => (
        Object.entries(data).map(([k, v]) => (
            v.visible && <tr key={k}>
                <td>{v.name}</td>
                <td>
                    {v.label ? v.label[v.value] && <h4><Badge bg={`${v.label[v.value].bg || 'secondary'}`}> {v.label[v.value].value} {v.unit || ""}</Badge></h4> : ` ${v.value} ${v.unit || ""}`}
                </td>
            </tr>
        ))
    );

    function renderPanels() {
        return <Card className="mb-2" style={{ background: '#1c1f23' }}>

            <Card.Body className="text-center">
                <Button variant="outline-info" size="sm" className="w-75 mb-3" onClick={() => setShowModal(true)}>
                    <i className="fas fa-chart-line"></i>&nbsp;&nbsp;- Trends&nbsp;&nbsp;
                </Button>
                <div className="mb-3">
                    <SvgIcon height={100} width={100} element={SVG.Icon['Senquip']} />
                </div>
                <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Body className="pt-1 pb-1">
                        {renderPreview()}
                        <Collapse in={expand}>
                            <div >
                                <hr />
                                <Table hover responsive="lg" className="mb-4">
                                    <tbody>
                                        {renderPanel()}
                                    </tbody>
                                </Table>
                            </div>
                        </Collapse>
                    </Card.Body>
                </Card>
                {renderStatus()}
            </Card.Body>
        </Card>
    }

    const renderStatus = () => {
        if (!data["LastSeen"]) return (
            <div className="d-flex justify-content-center mt-2">
                <SvgIcon height={20} width={20} element={SVG_Panel.Icon['Offline']} />
                &nbsp;
                <cite className='text-muted small m-0 ms-0'>
                    Offline
                </cite>
            </div>
        )
        const Last = data["LastSeen"];
        const offline = data["Offline"];
        const date = new Date(Last.value);
        const online = !HelperTime.isOlderThanNMinutes(date.getTime(), 120);
        return (
            <div className="d-flex justify-content-center mt-2">
                <SvgIcon height={20} width={20} element={SVG_Panel.Icon[online ? 'Online' : 'Offline']} />
                &nbsp;
                <cite className='text-muted small m-0 ms-0'>
                    {!data["LastSeen"].io ?
                        <span className={!online ? 'text-danger' : ''}>
                            {!online && "Offline "} Last seen <TimeAgo date={date} />
                        </span>
                        : "Live Data"}
                </cite>
            </div>
        );
    };

    return (
        <>
            <ChartModal show={showModal} handleClose={handleCloseModal} device={undefined} senquip={senquip} key={senquip?.id} />
            {renderPanels()}
        </>

    );
};

export default SenquipData