import React, { useEffect, useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode';
import './CustomStyles/QRCodeReader.css';

interface QRCodeReaderProps {
    onClose: () => void;
    onScanSuccess: (decodedText: string) => void;
}

const QRCodeReader: React.FC<QRCodeReaderProps> = ({ onClose, onScanSuccess }) => {
    const [feedback, setFeedback] = useState<string | null>(null);

    useEffect(() => {
        const scanner = new Html5QrcodeScanner(
            "qr-reader",
            {
                fps: 30,
                qrbox: { width: 250, height: 250 },
                aspectRatio: 1.0,
                rememberLastUsedCamera: true,
                supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
            },
            false
        );

        scanner.render(
            (decodedText) => {
                const regex = /^se:[^;]+;pi:[^;]+;t:[^;]+$/;
                if (regex.test(decodedText)) {
                    console.log("QR Code detected:", decodedText);
                    onClose();
                    onScanSuccess(decodedText);
                    scanner.clear();
                    setFeedback(null);
                } else {
                    setFeedback("Invalid QR Code format. Please try again.");
                    setTimeout(() => setFeedback(null), 3000);
                }
            },
            (error) => {
                
            }
        );

        return () => {
            scanner.clear();
        };
    }, [onClose, onScanSuccess]);

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Card className="mb-4 w-100" style={{ border: '1px solid #555', borderRadius: '8px', maxWidth: '400px' }}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="mx-auto">Scan QR Code</div>
                    <Button size='sm' variant='outline-warning' onClick={() => { onClose() }}><i
                        className="fa-solid fa-xmark"></i> </Button>
                </Card.Header>
                <Card.Body className="d-flex justify-content-center align-items-center">
                    <div id="qr-reader" className="w-100 h-100"></div>
                </Card.Body>
                {feedback && <Card.Footer className="text-center">
                    <Alert variant="danger" className="mt-3">{feedback}</Alert>
                </Card.Footer>}
            </Card>
        </div>
    );
};

export default QRCodeReader;